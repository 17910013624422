<!-- Localized -->
<template>
  <div class="flex ">
        <span class="h-8 w-8 ">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16.4375" r="16" fill="#858585"/>
                <path d="M10 22.7109L22.2332 10.4398" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <path d="M10 10.4375L22.2711 22.6707" stroke="white" stroke-width="3" stroke-linecap="round"/>
            </svg>
        </span>
        <span class="h-8 w-8 ">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16.4375" r="16" fill="#858585"/>
                <path d="M12.508 20.0465L11.449 21.1088L13.5736 23.2268L14.6326 22.1645L12.508 20.0465ZM23.9364 12.8318C24.5213 12.2451 24.5199 11.2954 23.9332 10.7105C23.3465 10.1256 22.3967 10.1271 21.8118 10.7138L23.9364 12.8318ZM14.6326 22.1645L23.9364 12.8318L21.8118 10.7138L12.508 20.0465L14.6326 22.1645Z" fill="white"/>
                <path d="M12.5097 22.1661L13.5703 23.2268L15.6916 21.1055L14.631 20.0448L12.5097 22.1661ZM9.72216 15.136C9.13637 14.5502 8.18662 14.5502 7.60083 15.136C7.01505 15.7218 7.01505 16.6715 7.60083 17.2573L9.72216 15.136ZM14.631 20.0448L9.72216 15.136L7.60083 17.2573L12.5097 22.1661L14.631 20.0448Z" fill="white"/>
            </svg>
        </span>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 'unselected',
            type: String
        }
    }
}
</script>

<style>

</style>