<!-- Localized -->
<template>
<div class="comparison-card">
    <div class="title px-4 py-2">
        {{label}}
    </div>
    <div class="content flex">
        <div
            v-for="field in cardConfig.fields"
            :key="field.prop"
            class="field-wrapper flex justify-between"
        >
            <component
                :is="field.component"
                :label="field.label"
                class="min-w-max"
                :[field.valueProp]="field.modifier ?
                    field.modifier(data[field.prop]) :
                    data[field.prop]"
            >
                <!-- <template #mark>
                    <div
                        v-if="showMark && !checkEqual(field.prop) && field.compare !== false"
                        class="mark"
                    ></div>
                </template> -->
            </component>
            <div class="flex-shrink-0">
                <SwitchList />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SelectField from './components/select'
import TextField from "./components/text-field";
import ToggleSwitch from "./components/toggle-switch";
import DocumentList from "./components/document-list";
import SwitchList from "./components/switch.vue"

class CardField {
  constructor(data) {
    this.label = data.label;
    this.prop = data.prop;
    this.modifier = data.modifier;
    this.component = data.component;
    this.compare = data.compare ?? true;
    this.valueProp = data.valueProp || 'initialValue';
  }
}

export default {
    name: 'comparison-card',
    props: {
        data: {
            type: Object,
            required: true,
        },
        compareData: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        showMark: Boolean,
    },
    components: {
        SwitchList
    },
    computed: {
        cardConfig() {
            return {
                fields: [
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.type'),
                        prop: 'type',
                        component: SelectField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.from_date'),
                        prop: 'date_from',
                        component: SelectField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.to_date'),
                        prop: 'date_to',
                        component: SelectField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.duration'),
                        prop: 'duration',
                        modifier: (val) => val + ' months',
                        component: SelectField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.position_held'),
                        prop: 'position',
                        component: TextField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.reason_for_leaving'),
                        prop: 'reason',
                        component: TextField,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.dismissed'),
                        prop: 'dismissed',
                        component: ToggleSwitch,
                    }),
                    new CardField({
                        label: this.$t('components.tenant.sc_journey.documents'),
                        prop: 'documents',
                        component: DocumentList,
                        compare: false,
                        valueProp: 'list',
                    }),
                ]
            }
        },
    },
    methods: {
        checkEqual(prop) {
            return this.data[prop] === this.compareData[prop];
        },
    },
}
</script>

<style lang="scss" scoped>
.comparison-card {
    font-weight: 600;
    display: inline-flex;
    flex-direction: column;
    border-radius: 16px;
    // width: 272px;
    .title {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        font-weight: bold;
        color: #fff;
        display: flex;
        align-items: center;
        // height: 42px;
        // padding: 0 24px;
        background-color: #0D69D5;
    }
    .content {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: #DFDFDF;
        gap: 16px;
        .field-wrapper {
            position: relative;
            .mark {
                width: 32px;
                height: 32px;
                background-color: #CBCDCB;
                border-radius: 8px;
                position: absolute;
                transform: translateX(50%);
                right: calc(-20px - 32px);
            }
        }
    }
}
</style>