<!-- Localized -->
<template>
<div class="comparison">
    <Card
        :label="$t('components.tenant.sc_journey.provided_by_subject')"
        :data="data.candidate"
        :compareData="data.referee"
        :showMark="true"
    />
    <Card
        :label="$t('components.tenant.sc_journey.provided_by_referee')"
        :data="data.referee"
        :compareData="data.candidate"
    />
</div>
</template>

<script>
import Card from "./components/card";

export default {
    name: 'comparison',
    components: {
        Card,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.comparison {
    display: flex;
    gap: 64px;
}
</style>