<!-- Localized -->
<template>
<div class="toggle-switch flex justify-between items-center">
    <div class="label">{{label}}</div>
    <div
        class="input bg-gray-300 h-4 flex items-center rounded-full p-1 duration-200 ease-in-out"
        @click.stop="$emit('click')"
        :class="optionalClass"
    >
        <div
            class="toggle-view bg-white w-4 h-4 rounded-full shadow-md transform duration-200 ease-in-out"
            :class="initialValue ? 'ml-auto order-1' :  'mr-auto' "
        ></div>
        <div
            class="text"
            :class="initialValue ? 'text-white-text mr-auto' :  'ml-auto' "
        >
            {{initialValue ? $t('general.yes') :  $t('general.no')}}
        </div>
    </div>
    <slot name="mark"></slot>
</div>
</template>

<script>
export default {
    name: "toggle-switch",
    components: {},
    props: ["initialValue", "disabled", "label"],
    data() {
        return {};
    },
    computed: {
        optionalClass() {
            if (this.disabled) {
                if (this.initialValue) {
                    return "bg-blue-600 cursor-not-allowed pointer-events-none";
                }
                return "bg-gray-100 cursor-not-allowed pointer-events-none  opacity-50";
            } else if (this.initialValue) return "bg-brand cursor-pointer bg-blue-600";
            else return 'cursor-pointer';
        },
    },
    mounted() {},
    methods: {},
};

</script>

<style lang="scss" scoped>
.toggle-switch {
    font-size: 14px;
    .input {
        width: 54px;
        height: 24px;
        box-sizing: border-box;
        .text {
            padding: 0 2px;
        }
    }
}
</style>